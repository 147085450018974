import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Layout, Dropdown, Button, Modal } from 'antd';
import { FaIndent, FaOutdent } from 'react-icons/fa';
import {getCommunity} from '../api/community';
import useToken from '@/utils/useToken';

const { removeToken } = useToken();

 function Navbar(props) {
  const { Header } = Layout;
  const [t,i18n] = useTranslation();
  const [title, setTitle] = React.useState(t("title"));
  const menuLanguages = [
    { label: '简体中文', key: 'zh', },
    { label: 'English', key: 'en', },
    { label: 'Espanol', key: 'es', },
  ];

  const getLanguageLabel = () => {
    switch(i18n.language) {
      case 'en': return 'English';
      case 'es': return 'Español';
      case 'zh': 
      default  : return '简体中文';
    }
  }

  const handleMenuClick = (e) => {
    i18n.changeLanguage(e.key);
  };

  const handleLogout = () => {
    Modal.confirm({
      itle: '提示',
      content: '确认退出系统？',
      onCancel: () => {},
      onOk: () => {
        removeToken()
      }
    })
  }

  React.useEffect(() => {
    getCommunity().then((res) => {
      setTitle(res.fullname);
    });
  }, []);

  return (
    <Header className="site-navbar" theme={props.theme == 'dark' ?  'light' : 'dark'}>
      <Button
        className="ant-siderbar-trigger"
        size="small" 
        type={props.collapsed ? "primary" : "text"}
        icon={props.collapsed ? <FaIndent /> : <FaOutdent />}
        onClick={props.toggleCollapsed}
        style={{ color: "white" }}
      />
      <span className="site-logo">{title}</span>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dropdown menu={{items:menuLanguages, onClick:handleMenuClick}} trigger={['click']} placement="bottomRight" >
          <Button size="small"  type="primary" style={{ color: "white" }}> {getLanguageLabel()} </Button>
        </Dropdown>
        <div style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleLogout}>退出</div>
      </div>
    </Header>
  )
}

Navbar.propTypes = {
  collapsed: PropTypes.bool,
  collapsedWidth: PropTypes.number,
  toggleCollapsed: PropTypes.func,
  theme: PropTypes.string,
}

export default Navbar;