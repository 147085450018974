
// import { Skeleton } from './Settings';
// import { formatTimestampToDate } from './Utils';
import Api from '../utils/api';
// import { calculateAge } from '../utils/utility';


// export const Album = (data) => {
// }

// export const getAlbums = (data) => {
// }


export const dataPictureFormatter = (picture) => {
    return {
        ...picture,
        key: picture.id,
        id: picture.id, //for remove it
        uid: picture.id,
        filename: picture.file,
        status: "done",

        // 这里对应到 onSuccess 的回调参数
        url: picture.fileurl,
        //   thumbUrl: uploadFile.response,
        lastModifiedTime: new Date(picture.mtime * 1000).toUTCString(),
    }
}

export const dataAppFormatter = (app) => {
    return {
        ...app,
        key: app.id,
        title: app.fullname,
        // hasstage: hall.hasstage ? '有' : '无',
    }
}

export const getSideBar = async () => {
    return await Api.get('/settings/sidebar').then((response) => {
        return response;
    });
}


export const getCommunity = async () => {
    return await Api.get('/settings/community').then((response) => {
        return response;
    });
}

export const getInstance = async () => {
    return await Api.get('/settings/instance').then((response) => {
        return response;
    });
}

export const setInstance = async (params) => {
    return await Api.put('/settings/instance', params).then((response) => {
        return response;
    });
}



export const getOss = async () => {
    return await Api.get('/settings/oss').then((response) => {
        return response;
    });
}

export const setOss = async (params) => {
    return await Api.formpost('/settings/oss', params).then((response) => {
        return response;
    });
}

export const getStorage = async () => {
    return await Api.get('/settings/storage').then((response) => {
        return response;
    });
}

export const setStorage = async (params) => {
    return await Api.put('/settings/storage', params).then((response) => {
        return response;
    });
}

export const getApps = async () => {
    return await Api.get(`/apps`).then((res) => {
        return res;
    })
}


export const getApp = async (appkey) => {
    return await Api.formpost(`/apps/${appkey}`).then((res) => {
        return res;
    })
}


export const getEnums = async () => {
    return await Api.get(`/enums`).then((res) => {
        return res;
    })
}
