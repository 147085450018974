import React, {useState, useEffect, Suspense} from 'react';
import { Outlet } from "react-router-dom";

import { Layout, } from 'antd';

// import { FaHome, FaTachometerAlt, FaTooth, FaHeart } from 'react-icons/fa';

import Sidebar from './Sidebar';
import Navbar from './Navbar';

import Loader from '../components/Loader/Dots.jsx';

// const { Header, Sider, Content, Footer } = Layout;

// class LayoutBase extends React.Component {
export default function Frame() {


  const [collapsed, setCollapsed] = useState(false);
  const [theme, setTheme] = useState('dark');
  const [mediaSize, setMediaSize] = useState('xl');
  const [collapsedWidth, setCollapsedWidth] = useState(0);
  const [expandedWidth, setExpandedWidth] = useState(160);


  const toggleCollapsed = () => {setCollapsed(!collapsed);};
  const toggleTheme     = () => {setTheme(theme === 'dark' ? 'light' : 'dark');};

  const  [frameProps, setFrameProps] = useState({
    mediaSize: mediaSize,
    collapsed: collapsed,
    theme : theme,
    collapsedWidth: collapsedWidth,
    expandedWidth: expandedWidth,
    setCollapsed: setCollapsed,
    setTheme: setTheme,
    toggleCollapsed: toggleCollapsed,
    toggleTheme: toggleTheme,
  });
  


  const initFrameProps = () => {
    // window on load
    window.matchMedia("(max-width: 575.98px)")                        .addEventListener('change', e => { if(e.matches) { setMediaSize('xs'); setCollapsedWidth(0);  setExpandedWidth(120)}});
    window.matchMedia("(min-width: 576px) and (max-width:  767.98px)").addEventListener('change', e => { if(e.matches) { setMediaSize('sm'); setCollapsedWidth(0);  setExpandedWidth(140)}});
    window.matchMedia("(min-width: 768px) and (max-width:  991.98px)").addEventListener('change', e => { if(e.matches) { setMediaSize('md'); setCollapsedWidth(50); setExpandedWidth(160)}});
    window.matchMedia("(min-width: 992px) and (max-width: 1199.98px)").addEventListener('change', e => { if(e.matches) { setMediaSize('lg'); setCollapsedWidth(50); setExpandedWidth(180)}});
    window.matchMedia("(min-width: 1200px)")                          .addEventListener('change', e => { if(e.matches) { setMediaSize('xl'); setCollapsedWidth(50); setExpandedWidth(200)}});
  }
  
  useEffect(() => {
    initFrameProps();
    // window on resize 
    window.addEventListener("resize", initFrameProps, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setFrameProps({
      mediaSize: mediaSize,
      collapsed: collapsed,
      theme : theme,
      collapsedWidth: collapsedWidth,
      expandedWidth: expandedWidth,
      setCollapsed: setCollapsed,
      setTheme: setTheme,
      toggleCollapsed: toggleCollapsed,
      toggleTheme: toggleTheme,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaSize, collapsed, theme, collapsedWidth, expandedWidth]);



  /**
   * Sidebar width: 
   *  (collapsedWidth) XS: 0, SM: 0, MD: 50, LG: 50, XL: 50
   * 
   * Navbar height: 64px
   * 
   */
  return (
    <Layout className="site-container">
      {/* <Navbar {...frameProps} />   */}
      {/* <Layout hasSider> */}
        <Sidebar {...frameProps} /> 
        <Layout className="site-layout" style={{height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
          <Navbar  {...frameProps} /> 
          <Suspense fallback={<Loader />}>
            <Outlet context={[frameProps, setFrameProps]} />  
          </Suspense>
        </Layout>
      {/* </Layout> */}
    </Layout>
  );
}