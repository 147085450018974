import React, {useEffect} from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './routes/app.js';
import {useTranslation} from 'react-i18next';
import "./i18n/index.js";
import { ConfigProvider, theme, App as AntApp } from 'antd';
import { validLicenseSettings } from './utils/api.js';
import { Enums,Categories } from './utils/settings.js';
import { getEnums } from './api/community.js';
import { getCategories } from './api/category.js';
import 'dayjs/locale/zh-cn';
import "./App.css"

window._AMapSecurityConfig = {
  serviceHost:'//api.betasys.cn/_AMapService',
}

validLicenseSettings();

const App = () => {
  const {i18n} = useTranslation();

  useEffect(() => {
    validLicenseSettings();

    getEnums().then((res) => {
      for (let [key, value] of Object.entries(res)) {
        Enums[key] = value;
      }
    });

    getCategories({ filter: { type: 'events' } }).then(res => {
      Categories.entry = res.list;
    });

    document.title = i18n.t('title');
  }, [i18n]);

  const tplTheme = {
    token: {
      colorPrimary: '#1890ff',
      borderRadius: 4,
      wireframe: true,
      "fontSize": 15,
    },
    // 5.0 版本中默认提供三套预设算法，分别是默认算法 theme.defaultAlgorithm 、暗色算法 theme.darkAlgorithm 和紧凑算法 theme.compactAlgorithm
    // algorithm: theme.defaultAlgorithm,
    algorithm: theme.compactAlgorithm,
    // components: {}
  };

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </React.Suspense>
  );
}

export default App;