
import React from "react";
import { createHashRouter, Navigate } from "react-router-dom";
import LayoutFrame from "../layout/Frame"
import useToken from '@/utils/useToken.js'

const Signin          = React.lazy(() => import("../pages/Auth/Signin"));
const Home          = React.lazy(() => import("../pages/Home/index"));
const Users         = React.lazy(() => import("../pages/Users/index"));
const Institutions  = React.lazy(() => import("../pages/Institutions/index"));
const Collaborators = React.lazy(() => import("../pages/Collaborators/index"));
const Events        = React.lazy(() => import("../pages/Events/index"));
const Errors        = React.lazy(() => import("../pages/Errors/index"));

const router = createHashRouter([
  { path: '/signin', Component: Signin },
  { path: "/", element: <AuthComponent><LayoutFrame /></AuthComponent>, errorElement: <Errors />, children: [
    { path: "home/*", element: <Home /> },
    { path: "users/*", element: <Users /> },
    { path: "institutions/*",  element: <Institutions /> },
    { path: "collaborators/*", element: <Collaborators /> },
    { path: "events/*",        element: <Events /> },
    { path: "errors",        element: <Errors /> },
  ]}
]);

function AuthComponent({ children }) {
  const { getToken } = useToken()
  return getToken() ? children : <Navigate to={'/signin'}></Navigate>
}

export default router;