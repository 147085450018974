
// import { Skeleton } from './Settings';
// import { formatTimestampToDate } from './Utils';
import Api from '../utils/api';
// import { calculateAge } from '../utils/utility';


// export const Album = (data) => {
// }

// export const getAlbums = (data) => {
// }


// export const dataPictureFormatter = (picture) => {
//     return {
//         ...picture,
//         key: picture.id,
//         id: picture.id, //for remove it
//         uid: picture.id,
//         filename: picture.file,
//         status: "done",

//         // 这里对应到 onSuccess 的回调参数
//         url: picture.fileurl,
//         //   thumbUrl: uploadFile.response,
//         lastModifiedTime: new Date(picture.mtime * 1000).toUTCString(),
//     }
// }

// export const dataAppFormatter = (app) => {
//     return {
//         ...app,
//         key: app.id,
//         title: app.fullname,
//         // hasstage: hall.hasstage ? '有' : '无',
//     }
// }

export const getCategories = async (params = {}) => {
    return await Api.get(`/categories`, params).then((res) => {
        return res;
    })
}

