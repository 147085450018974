import React from 'react'

import './Dots.css'

export default function Dots() {
  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
    <figure className="suspense-loader">
        <div className="dot white"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
    </figure>
  </div>
  )
}
