import { Settings } from './settings';

const useToken = () => {
  const setToken = (token) => {
    localStorage.setItem(Settings.tk, token);
  };

  const getToken = () => {
    const token = localStorage.getItem(Settings.tk);
    return token;
  };

  const removeToken = () => {
    if (localStorage.getItem(Settings.tk)) {
      localStorage.removeItem(Settings.tk);
      window.alert('令牌已过期，请重新登录');
      window.location.reload();
    }
  };

  return {
    setToken,
    getToken,
    removeToken,
  }
}
export default useToken;