import React from 'react'
import PropTypes from 'prop-types'
// import {useEffect} from 'react'
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Layout, Menu } from 'antd';
// import {
//   // MenuUnfoldOutlined,
//   // MenuFoldOutlined,
//   // UserOutlined,
//   // VideoCameraOutlined,
//   // UploadOutlined,
//   // PrinterOutlined,
//   // MailOutlined,
//   // DashOutlined,
//   // RedoOutlined,
//   // SettingOutlined,
// } from '@ant-design/icons';

import {
  FaHome,           //Home
  FaHotel,          //Hotel
  FaNewspaper,      //News
  FaUserFriends,    //User
  FaUniversity,     //Institution
  FaCalendarAlt,    //Event
  FaBarcode,        //product
  FaBuilding,       //Company
  FaAddressCard,    //People
  FaHandshake,      //Collaborator
  FaImage,          //ALbum
  FaFolderOpen,     //Folder/
  FaBookDead,       //Manga
  FaTrademark,      //Brand

  FaGlobe,          //Globe site
  FaCogs,           //Cogs //Setting
  // FaFingerprint,    //Fingerprint
  
} from 'react-icons/fa';

// import { getSideBar } from '../../api/community';

import logo from '../assets/images/logo.png';

function Sidebar(props) {

  const { Sider } = Layout;
  const [t, i18n] = useTranslation();
  const location = useLocation();


  const [selectedKeys, setSelectedKeys] = React.useState([]);
  const [siderItems, setSiderItems] = React.useState([]);

  const sidebarClickHandle = () => {
    //  in_array(mediaSize, ['xs', 'sm'])
    props.mediaSize === 'sm' && props.setCollapsed(true);
    props.mediaSize === 'xs' && props.setCollapsed(true);
  };

  const allSiderItems = [
    {key: 'home',         icon: <FaHome />,          label: <Link onClick={sidebarClickHandle} to="/home">{t("sidebar.home")}</Link>,},
    {key: 'people',       icon: <FaAddressCard />,   label: <Link onClick={sidebarClickHandle} to="/people">{t("sidebar.people")}</Link>,},
    {key: 'users',        icon: <FaUserFriends />,   label: <Link onClick={sidebarClickHandle} to="/users">{t("sidebar.users")}</Link>,},
    {key: 'institutions', icon: <FaUniversity />,    label: <Link onClick={sidebarClickHandle} to="/institutions">{t("sidebar.institutions")}</Link>,},
    {key: 'collaborators',icon: <FaHandshake />,     label: <Link onClick={sidebarClickHandle} to="/collaborators">{t("sidebar.collaborators")}</Link>,},
    {key: 'companies',    icon: <FaBuilding />,      label: <Link onClick={sidebarClickHandle} to="/companies">{t("sidebar.companies")}</Link>,},
    {key: 'articles',     icon: <FaNewspaper />,     label: <Link onClick={sidebarClickHandle} to="/articles">{t("sidebar.articles")}</Link>,},
    {key: 'events',       icon: <FaCalendarAlt />,   label: <Link onClick={sidebarClickHandle} to="/events">{t("sidebar.events")}</Link>,},
    {key: 'tutos',        icon: <FaBarcode />,       label: <Link onClick={sidebarClickHandle} to="/tuttos">{t("sidebar.products")}</Link>,},
    {key: 'sites',        icon: <FaGlobe />,         label: <Link onClick={sidebarClickHandle} to="/sites">{t("sidebar.sites")}</Link>,},
    {key: 'brands',       icon: <FaTrademark />,     label: <Link onClick={sidebarClickHandle} to="/brands">{t("sidebar.brands")}</Link>,},
    {key: 'hotels',       icon: <FaHotel />,         label: <Link onClick={sidebarClickHandle} to="/hotels">{t("sidebar.hotels")}</Link>,},

    {key: 'albums',       icon: <FaImage />,         label: <Link onClick={sidebarClickHandle} to="/albums">{t("sidebar.albums")}</Link>,},
    {key: 'folders',      icon: <FaFolderOpen />,    label: <Link onClick={sidebarClickHandle} to="/folders">{t("sidebar.folders")}</Link>,},
    {key: 'mangas',       icon: <FaBookDead />,      label: <Link onClick={sidebarClickHandle} to="/mangas">{t("sidebar.mangas")}</Link>,},
    {key: 'settings',     icon: <FaCogs />,          label: <Link onClick={sidebarClickHandle} to="/settings">{t("sidebar.settings")}</Link>,},

    // {key: 'test',         icon: <FaBug />,           label: <Link onClick={sidebarClickHandle} to="/test">{t("sidebar.test")}</Link>,},
    // {key: 'about',     icon: <FaFingerprint />,   label: <Link onClick={sidebarClickHandle} to="/about">{t("sidebar.about")}</Link>,},
  ];

  const portal_cdec_org_cn  = [
    {key: 'home',         icon: <FaHome />,          label: <Link onClick={sidebarClickHandle} to="/home">{t("sidebar.home")}</Link>,},
    {key: 'users',        icon: <FaUserFriends />,   label: <Link onClick={sidebarClickHandle} to="/users">{t("sidebar.users")}</Link>,},
    {key: 'institutions', icon: <FaUniversity />,    label: <Link onClick={sidebarClickHandle} to="/institutions">{t("sidebar.institutions")}</Link>,},
    {key: 'collaborators',icon: <FaHandshake />,     label: <Link onClick={sidebarClickHandle} to="/collaborators">{t("sidebar.collaborators")}</Link>,},
    {key: 'events',       icon: <FaCalendarAlt />,   label: <Link onClick={sidebarClickHandle} to="/events">{t("sidebar.events")}</Link>,},
  ];

  const portal_dingdangdang_cc = [
    {key: 'home',         icon: <FaHome />,          label: <Link onClick={sidebarClickHandle} to="/home">{t("sidebar.home")}</Link>,},
    {key: 'users',        icon: <FaUserFriends />,   label: <Link onClick={sidebarClickHandle} to="/users">{t("sidebar.users")}</Link>,},
    {key: 'mangas',       icon: <FaBookDead />,      label: <Link onClick={sidebarClickHandle} to="/mangas">{t("sidebar.mangas")}</Link>,},
    {key: 'articles',     icon: <FaNewspaper />,     label: <Link onClick={sidebarClickHandle} to="/articles">{t("sidebar.articles")}</Link>,},
  ];

  const portal_tuttocapsule_ec = [
    {key: 'home',         icon: <FaHome />,          label: <Link onClick={sidebarClickHandle} to="/home">{t("sidebar.home")}</Link>,},
    {key: 'brands',       icon: <FaTrademark />,     label: <Link onClick={sidebarClickHandle} to="/brands">{t("sidebar.brands")}</Link>,},
    {key: 'tutos',        icon: <FaBarcode />,       label: <Link onClick={sidebarClickHandle} to="/tuttos">{t("sidebar.products")}</Link>,},
    {key: 'sites',        icon: <FaGlobe />,         label: <Link onClick={sidebarClickHandle} to="/sites">{t("sidebar.sites")}</Link>,},
  ];

  const hotel_betasys_cn = [
    {key: 'people',       icon: <FaAddressCard />,   label: <Link onClick={sidebarClickHandle} to="/people">{t("sidebar.people")}</Link>,},
    {key: 'users',        icon: <FaUserFriends />,   label: <Link onClick={sidebarClickHandle} to="/users">{t("sidebar.users")}</Link>,},
    {key: 'hotels',       icon: <FaHotel />,         label: <Link onClick={sidebarClickHandle} to="/hotels">{t("sidebar.hotels")}</Link>,},
  ];
  const  domainSiderItems =  {
    'default'                : allSiderItems,
    'localhost'              : portal_cdec_org_cn, //test
    'portal-cdec.huahanwenhua.com' : portal_cdec_org_cn, //test

    'portal.betasys.cn'      : allSiderItems,
    'hotel.betasys.cn'       : hotel_betasys_cn,
    'portal.dingdangdang.cc' : portal_dingdangdang_cc,
    'portal.cdec.org.cn'     : portal_cdec_org_cn,
    'portal.tuttocapsule.ec' : portal_tuttocapsule_ec,
  } 





  React.useEffect(() => {

    let domain = window.location.hostname;
    let siderItems = domainSiderItems['default'];

    if (domainSiderItems[domain]) {
      siderItems = domainSiderItems[domain];
      
      let selectedKeys = [];
      siderItems.forEach((item) => {
        if (location.pathname.indexOf(item.key) > -1) {
          selectedKeys.push(item.key);
        }
      });
      setSelectedKeys(selectedKeys);
    
    } 

    
    setSiderItems(siderItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);


  return (
    <Sider
      theme={props.theme}
      className='site-sidebar'
      width={props.expandedWidth ?  props.expandedWidth : "12.5vw"}
      collapsible
      collapsedWidth={props.collapsedWidth}
      collapsed={props.collapsed}
      trigger={null}
      breakpoint="sm"
      onBreakpoint={broken => {
        if (broken && !props.collapsed) {
          props.toggleCollapsed();
        }
        if (!broken && props.collapsed) {
          props.toggleCollapsed();
        }
      }}
      onCollapse={(value) => props.toggleCollapsed(value)}
    >
       <div className="site-logo">
            <img alt="logo" src={logo}  style={{width:"36px", height:"36px"}}/>
            {!props.collapsed && <h1 style={{paddingLeft:"8px"}}>管理系统</h1>}
        </div>


      <Menu
        // theme={props.theme}
        theme={props.theme}
        mode="inline" //vertical, horizontal, inline
        defaultSelectedKeys={selectedKeys}
        style={{marginTop:0}}
        items={siderItems}
      />

      {/* <Menu
        // theme={props.theme}
        // theme={props.theme === "dark" ? "light" : "dark"}
        mode="inline" //vertical, horizontal, inline
        defaultSelectedKeys={['1']}
        style={{bottom:0, position:"absolute", width:"100%"}}
        items={[
          // {key: 'settings', icon: <FaCogs />,          label: <Link to="/blank">{t("sidebar.settings")}</Link>,},
          {key: "trigger",  icon: props.collapsed ? <FaIndent/> : <FaOutdent />,         label: props.collapsed ? "展开侧栏" : "收起侧栏", onClick: props.toggleCollapsed,},
          // <Button className="ant-siderbar-trigger" size="small" type="primary" onClick={props.toggleCollapsed} icon={props.collapsed ? <FaIndent /> : <FaOutdent />} />
        ]}
      />  */}
    </Sider>
  )
}

Sidebar.propTypes = {
  theme: PropTypes.string,
  collapsed: PropTypes.bool,
  collapsedWidth: PropTypes.number,
  expandedWidth: PropTypes.number,
  toggleCollapsed: PropTypes.func,
  mediaSize: PropTypes.string,
  setCollapsed: PropTypes.func,
}

export default Sidebar;